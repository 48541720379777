import React, { useState, useEffect } from 'react';

import data from '@emoji-mart/data';
import Picker from '@emoji-mart/react';
import Editor from 'draft-js-plugins-editor';
import { EditorState, Modifier, ContentState, convertFromRaw, convertToRaw  } from 'draft-js';

import createToolbarPlugin from 'draft-js-static-toolbar-plugin';
import 'draft-js-static-toolbar-plugin/lib/plugin.css';
import 'draft-js/dist/Draft.css';

import { BiCool } from 'react-icons/bi';

import './styles/request-edit-modal.css';

const staticToolbarPlugin = createToolbarPlugin();
const { Toolbar } = staticToolbarPlugin;
const plugins = [staticToolbarPlugin];

function RequestEditModal({ reqItem, onClose, onUpdate }) {
    
    const titlePlaceholder = "What experience would you like to request from others?";
    const contentPlaceholder = "";
    const maxCharNum = 170;
    
    const initialContent = ContentState.createFromText(contentPlaceholder);
    const [emojiPicker, setEmojiPicker] = useState(false);

    const [requestItemToEdit, setRequestItemToEdit] = useState(null);
    const [requestItemToEditTitle, setRequestItemToEditTitle] = useState("");
    const [editorState, setEditorState] = useState(EditorState.createWithContent(initialContent));

    const [editRequestValidateMessage, setEditRequestValidateMessage] = useState("");
    const [validateMessage, setValidateMessage] = useState('');

    const onClickEmojiPicker = () => {
        setEmojiPicker(!emojiPicker);
    };

    const onEditorEmojiSelect = (emoji) => {
        const selection = editorState.getSelection();
        let contentState = editorState.getCurrentContent();
        const emojiStr = emoji.native;
        contentState = Modifier.replaceText( contentState, selection, emojiStr );
        setEditorState( EditorState.push(editorState, contentState, 'insert-characters') );
        setEmojiPicker(false);
    };

    const hideEditRequestValidateMessage = () => {
        setEditRequestValidateMessage("");
    };

    const onRequestItemEditTitleChange = (e) => {
        setRequestItemToEditTitle(e.target.value);
        e.target.style.height = "auto";
        e.target.style.height = `${e.target.scrollHeight}px`;
    };

    const onSave = async () => {
        
        const contentState = editorState.getCurrentContent();
        const requestText = contentState.getPlainText();      
        if (requestItemToEditTitle === "") {
            //show validation msg, then return
            setValidateMessage("Please enter your question.");
            setTimeout(()=>{ setValidateMessage("");}, 5001);
            return;
        }
        let noteContent = "";
        if (requestText.trim() !== "") { 
            const rawContentState = convertToRaw(contentState);
            const contentJSONString = JSON.stringify(rawContentState);
            noteContent = contentJSONString;
        } 
        const requestToUpdate = {note: noteContent, question: requestItemToEditTitle};
        const result = await updateRequest(reqItem._id, requestToUpdate); 

        onClose();
        onUpdate();
    };

    const updateRequest = async (requestId, requestToUpdate) => {
        try {
            const response = await fetch(`/requests/${requestId}/update`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    question: requestToUpdate.question,
                    note: requestToUpdate.note,
                }),
            });
    
            // Check if the request was successful
            if (!response.ok) {
                throw new Error(`Error: ${response.statusText}`);
            }
    
            const data = await response.json();
            console.log('Request updated successfully:', data.message);
        } catch (error) {
            console.error('Error updating the request:', error.message);
        }
    }

    const onCancel = () => {
        //cancel
        onClose();
    }

    useEffect(() => {
        if (reqItem) {
            setRequestItemToEdit(reqItem);
            setRequestItemToEditTitle(reqItem.question_content);
            const contentState = convertFromRaw(JSON.parse(reqItem.note));
            setEditorState(EditorState.createWithContent(contentState));
        }
    }, [reqItem]);


    return (
        <div className='request-edit-modal'>
            <div className='request-detail-div request-edit-modal-inner-div'>
            <textarea class="form-control request-title-textarea" name="question-title"  as="textarea" value={requestItemToEditTitle}
                        onChange={onRequestItemEditTitleChange} placeholder={titlePlaceholder} maxLength={maxCharNum}  />
            {<div className='remaining-chars-div'> {maxCharNum} characters remaining </div>}
            <Editor className="editor-area" editorState={editorState} onChange={setEditorState} plugins={plugins} />
            <div className='editor-bottom-bar'>
                {/*stay to the left*/}
                <div className="request-editor-toolbar-div"><Toolbar></Toolbar></div>
                {/*<button className="request-edit-modal-inner-div-btn" onClick={()=>onClickEmojiPicker()}><BiCool/></button>*/}
                {/*emojiPicker ? (<Picker className="emj-picker" data={data} onEmojiSelect={onEditorEmojiSelect} />) : null*/}
                {/*below two button stay to the right*/}
                <div className='request-editor-btns-div'>
                    <button className="request-edit-modal-inner-div-btn request-editor-save-btn" onClick={onSave} >Save</button>
                    <button className="request-edit-modal-inner-div-btn" onClick={onCancel}>Cancel</button>
                </div>
            </div>
            <div className="validate-comment-msg" onClick={()=>hideEditRequestValidateMessage()}>{editRequestValidateMessage}</div>
            </div>
        </div>
    );
  }

  export default RequestEditModal;