import React, { useState, useEffect, useContext, useRef } from 'react';
import CommentPanel from './CommentPanel';
import ConfirmModal from './ConfirmModal';
import Carousel from 'react-elastic-carousel'
import { AiOutlineYoutube, AiOutlineLink, AiOutlineCloseCircle } from "react-icons/ai";
import ThumbUpAltOutlinedIcon from '@mui/icons-material/ThumbUpAltOutlined';
import HighlightOffTwoToneIcon from '@mui/icons-material/HighlightOffTwoTone';
import { UserContext } from "./UserProvider";
import { extractVideoIds, extractYouTubeVideoID, saveVideoInfoToSession, getVideoInfoFromSession, fetchTitleAndThumb } from "./videohelper";
import { fetchAndCacheImage } from "./imageHelper";
import axios from "axios";
import appConfig from "./config.json";
import "./styles/post-box.css";

// this is a class component to display the item details
// shows when the square item is clicked
const RecordDetailsModal = ({ recordItem, onClose }) => {

    const [editMode, setEditMode] = useState(false); // editMode state
    const [showVideo, setShowVideo] = useState(false);
    const [currentVideoURL, setCurrentVideoURL] = useState('');
    const [isConfirmDeleteBoxOpen, setIsConfirmDeleteBoxOpen] = useState(false);
    const [numOfLikedUsers, setNumOfLikedUsers] = useState(0);
    const { loginUserId } = useContext(UserContext);
    const [isLiked, setIsLiked] = useState(false);
    const [stepsVideoInfoItems, setStepsVideoInfoItems] = useState([]);

    const [imageSrcMap, setImageSrcMap] = useState({});
    const [loadingMap, setLoadingMap] = useState({});

    

    const confirmDeleteStepTxt = "Are you sure you want to delete this learning experience?";
    const closeBtnRef = useRef(null);
    const carouselRef = useRef();
    //const carouselDivRef = useRef();

    const [activeIndex, setActiveIndex] = useState(0);
    const [carouselHeight, setCarouselHeight] = useState('300px');
    const handleImageLoad = (e) => {
        if (carouselRef.current) {
            // Update the height of the carousel container based on the loaded image's height
            if(e.target.height === 0) {
                return;
            }
            setCarouselHeight(e.target.height);
        }
    };

    const handleCarouselChange = (currentItem, currentIndex) => {
        // for debug
        console.log(currentIndex); 
        setActiveIndex(currentIndex); // Track the active index in the carousel
    };

    const buttonStyle = {
        backgroundColor: isLiked ? '#6B8BFF' : 'initial', // Set to blue if liked, else initial (default)
        color: isLiked ? '#000000' : 'initial', // Set to blue if liked, else initial (default)
    };
    
    const iconStyle = {
        transform: isLiked ? 'scale(1.5)' : 'scale(1)', // Add a little jump effect
        transition: 'transform 0.2s ease', // Apply a smooth transition
    };

    useEffect(() => {
        // Update the height of the carousel container based on the currently displayed item's height
        const crsDivs = document.querySelectorAll('.crs-div');
        const activeDiv = crsDivs[activeIndex];
        const activeImage = activeDiv ? activeDiv.querySelector('img') : null;
        if (activeImage && activeImage.complete) {
            if(activeImage.height === 0) {
                return;
            }
            setCarouselHeight(activeImage.height);
        }
    }, [activeIndex]);

    useEffect(() => {
        if (recordItem._id && loginUserId) {
            getIfLiked();
        }
    }, [recordItem._id, loginUserId]);

    useEffect(() => {
        if (recordItem.liked_users) {
            setNumOfLikedUsers(recordItem.liked_users.length);
        }
        const videoIds = extractVideoIds(recordItem);
        if (videoIds.length === 0) {
            return;
        }
        updateLocalVideoInfo(videoIds);

        loadImages();


    }, [recordItem]);


    const loadImages = async () => {
        const loadingMapCopy = { ...loadingMap };

        await Promise.all(
            recordItem.steps.map(async (stepItem) => {
                const resItem = stepItem.resources[0];
                if (resItem && resItem.type === 'image' && resItem.content) {
                    const contentId = resItem.content;

                    // Set loading status for this image
                    loadingMapCopy[contentId] = true;
                    setLoadingMap({ ...loadingMapCopy });

                    const src = await fetchAndCacheImage(contentId);

                    setImageSrcMap((prevMap) => ({
                        ...prevMap,
                        [contentId]: src,
                    }));

                    // Mark loading as complete for this image
                    setLoadingMap((prevLoadingMap) => ({
                        ...prevLoadingMap,
                        [contentId]: false,
                    }));
                }
            })
        );
    };

    const updateLocalVideoInfo = async (videoIds) => {
        const videoInfoPromises = videoIds.map(async (videoId) => {
            const cachedVideoInfo = getVideoInfoFromSession(videoId);
            if (cachedVideoInfo) {
                return cachedVideoInfo;
            } else {
                const videoInfo = await fetchTitleAndThumb(videoId);
                if (videoInfo) {
                    saveVideoInfoToSession(videoId, videoInfo);
                    return videoInfo;
                }
            }
        });
    
        const allVideoInfo = await Promise.all(videoInfoPromises);
        setStepsVideoInfoItems(allVideoInfo.filter(t => t !== undefined));
    };

    const getIfLiked = async () => {
        try {
            // Replace `YOUR_BACKEND_URL` with the actual backend URL
            const response = await axios.get(`${appConfig.SERVER}/records/${recordItem._id}/${loginUserId}/isliked`);
            setIsLiked(response.data.isLiked);
        } catch (error) {
            console.error('Error fetching like status:', error);
        }
    };

    const toggleEditMode = () => {
        setEditMode((prevEditMode) => !prevEditMode); // toggle editMode state
        window.location.href = `/browsing?editId=${recordItem._id}`;
    };

    const onDeleteBtnClick = () => {
        setIsConfirmDeleteBoxOpen(true);
    };

    const onDeleteModalConfirm = async () => {
        setIsConfirmDeleteBoxOpen(false);
        await deleteRecordItem();
        //delete the record here
        if (closeBtnRef.current) {
            closeBtnRef.current.click();
            console.log("deleting below record");
            console.log(recordItem);
        } 
    };

    const deleteRecordItem = async () => {
        try {
          const deleteId = recordItem._id;
          const response = await fetch(`/records/${deleteId}/delete`, {
            method: 'DELETE',
          });
          if (!response.ok) {
            throw new Error(`HTTP error ${response.status}`);
          }
        } catch (error) {
          console.error('Failed to delete RequestItems:', error);
        }
    };

    const onDeletionModalBoxCancel = () => {
        setIsConfirmDeleteBoxOpen(false);
    }

    const onCloseBtnClick = () => {
        onClose();
    };

    const onLikedBtnClick = async () => {

        setIsLiked(!isLiked);
        if (isLiked) {
            await disLikeRecord();

            setNumOfLikedUsers(numOfLikedUsers-1);

            const userIndex = recordItem.liked_users.indexOf(loginUserId);
            if (userIndex !== -1) {
              recordItem.liked_users.splice(userIndex, 1);
            }
        } else {
            await likeRecord();
            setNumOfLikedUsers(numOfLikedUsers+1);
            recordItem.liked_users.push(loginUserId);
        }

    };

    const likeRecord = async () => {
        const likeRequest = { likingUserId: loginUserId };
        try { 
            const response = await fetch(`/records/${recordItem._id}/like`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(likeRequest)
            });
            const respondData = await response.json();
            return respondData;
        } catch (error) {
            console.log("error occurred: ", error);
        }
    };

    const disLikeRecord = async () => {
        try { 
            const response = await fetch(`/records/${recordItem._id}/dislike`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ dislikingUserId: loginUserId })
            });
            const responseData = await response.json();
            return responseData;
        } catch (error) {
            console.log("Error occurred: ", error);
        }
    };

    const renderStepItem = (stepItem, stepIndex) => {
        const resItem = stepItem.resources[0];
        if (!resItem) return null;
    
        // Check if the step contains an image type resource and has corresponding video info
        if (resItem.type === 'image') {
            const contentId = resItem.content;

            if (loadingMap[contentId]) {
                return <p>Loading image...</p>;
            }

            const imageSrc = imageSrcMap[contentId];
            return imageSrc ? (<><div className='crs-title'>{stepItem.title}</div>
                <img 
                    src={imageSrc} 
                    alt={`Step ${stepIndex}`} 
                    style={{ maxWidth: '100%', maxHeight: '600px', width: 'auto', height: 'auto' }} 
                    onLoad={handleImageLoad} /></>
            ) : (
                <p>Loading image...</p>
            );
        }

        if (stepsVideoInfoItems[stepIndex] && stepsVideoInfoItems[stepIndex].thumbUrl === "content") {
            return <>
                <div className='crs-title'>{stepItem.title}</div>
                {/*<img className='crs-thumb-img' alt='step-img' title="youtube video" src={require("./images/content-img.jpg")} onClick={() => onResItemClick(resItem)}/>
                <span class="crs-thumb-tooltip">{stepsVideoInfoItems[stepIndex].title}</span> */} 
                {renderContentItemDiv(stepItem)}
            </>
        }
        else {
            return <>
                {stepsVideoInfoItems[stepIndex] && <>
                <div className='crs-title'>{stepItem.title}</div>
                <img className='crs-thumb-img' alt='step-img' title="youtube video" src={stepsVideoInfoItems[stepIndex].thumbUrl} onClick={() => onResItemClick(resItem)}/>
                <span class="crs-thumb-tooltip">{stepsVideoInfoItems[stepIndex].title}</span> 
                </>}
            </>
        }
    };

    const renderContentItemDiv = (stepItem) => {
        console.log(stepItem);
        const content = stepItem?.resources?.[0]?.content ?? "null content";
        
        return <div className='step-content-div'>{content}</div>
    };

    const onResItemClick = (resItem) => {
        //console.log(resItem);
        if (!resItem) {
            console.error("resource item is null.");
            return;
        }
        //if it is a web page
        if (resItem.type === 'webpage') {
            openUrlInNewTab(resItem.content);
        }
        else if (resItem.type === 'youtube') {
    
            const ytVideoID = extractYouTubeVideoID(resItem.content);
            if (ytVideoID) {
                playVideo("https://www.youtube.com/embed/" + ytVideoID);
            }
        }
    };

    /**
     * handler to open the URL in a new window/tab
     */ 
    const openUrlInNewTab = (url) => {
        window.open(url, '_blank');
    };

    /**
     * play video
     */
    const playVideo = (url) => {
        setCurrentVideoURL(url);
        setShowVideo(true);
    };

    /**
     * close video
     */
    const closeVideo =() => {
        setShowVideo(false);
        setCurrentVideoURL("");
    };

    return (
        <>
            <div className='pb-main-dialog'>
                <div class="modal-dialog">
                    <div class="modal-content square-detail-modal-content">
                        {/* the header - user image - title */}
                        <div class="modal-header square-detail-header">
                            <div className="square-detail-header-upper">
                                <div className='auth-header-div'>
                                    <div className="auth-init-round"><img className="auth-init-round" src={recordItem.userPicture} alt='user' /></div>
                                    <div className="auth-name">{recordItem.userName}</div>
                                </div>
                                <button class="square-detail-close-btn" onClick={onCloseBtnClick}><HighlightOffTwoToneIcon fontSize='medium'/></button>
                            </div>
                            <div className="square-detail-header-bottom">
                                <div className='square-title-text'>{recordItem.title}</div>
                            </div>
                        </div>

                        {/* step items */}
                        <div className='learning-records-container' >
                            <Carousel 
                                className='crs-container'
                                ref={carouselRef}
                                onChange={handleCarouselChange}
                                itemsToShow={1}
                                showArrows={true}
                                pagination={true} >
                                {recordItem.steps.map((stepItem, index) => {
                                    return <div className='crs-div'>
                                                {renderStepItem(stepItem, index)}
                                            </div>
                                })}
                            </Carousel>
                        </div>

                        {/* operation items */}
                        <div class="square-list-res-operation-row">
                            {/*renderLikedText(recordItem)*/}
                            <div className='square-list-like'>{numOfLikedUsers + " users liked this learning experience"}</div>
                            <div className='square-list-btns'>
                            { loginUserId && 
                                (<button className="square-list-res-like-btn" 
                                        style={buttonStyle} 
                                        onClick={()=>onLikedBtnClick()} ><span style={iconStyle}><ThumbUpAltOutlinedIcon fontSize='10px' /></span></button>) } 
                            </div>
                        </div>

                        {/* comments */}
                        {loginUserId ? (<CommentPanel recordId={recordItem._id} commtHost={"record"} ></CommentPanel>) : (<div className='login-see-commts-div'>Please login to see comments.</div>)}
                    </div>
                </div>
            </div>

            {showVideo && (
                <div className="video-popup">
                    <div className="video-wrapper">
                        <iframe src={`${currentVideoURL}`} title={`YouTube video player`}
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                    </div>
                    <div className='close-btn-div'><button className='close-video-btn' onClick={() => closeVideo()}><AiOutlineCloseCircle /></button></div>
                </div>
            )}

            {/* modal box for confirm delete */}
            {isConfirmDeleteBoxOpen && <ConfirmModal onConfirm={onDeleteModalConfirm} onCancel={onDeletionModalBoxCancel} contentText={confirmDeleteStepTxt} />}
        </>
    );
};
  
export default RecordDetailsModal;

