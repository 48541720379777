/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import ThumbUpAltOutlinedIcon from '@mui/icons-material/ThumbUpAltOutlined';
import { extractVideoIds, saveVideoInfoToSession, getVideoInfoFromSession, fetchTitleAndThumb } from "./videohelper";
import "./styles/learning-post.css";
import { fetchAndCacheImage } from "./imageHelper";

const LearningPostTile = ({ learningRecordData }) => {

    const [learningRecord, setLearningRecord] = useState(null);
    const [thumbnail1URL, setThumbnail1URL] = useState("content"); //default as content image
    const [thumbnail2URL, setThumbnail2URL] = useState("content"); //default as content image
    const [thumbnail3URL, setThumbnail3URL] = useState("content"); //default as content image

    useEffect(async () => {
        setThumbnail1URL("");
        setLearningRecord(learningRecordData);

        var stepItem1 = learningRecordData.steps[0];
        if(stepItem1 && stepItem1.resources[0]) {
            var resItem = stepItem1.resources[0];
            if(resItem.type === "image") {
                const imageId = resItem.content;
                const imageUrl = await fetchAndCacheImage(imageId);
                setThumbnail1URL(imageUrl);
                return;
            }
        }
         
        const videoIds = extractVideoIds(learningRecordData);
        if (videoIds.length === 0) {
            return;
        }
        const cachedVideoInfo = getVideoInfoFromSession(videoIds[0]);
        initalizeThumbnails(cachedVideoInfo, videoIds[0], setThumbnail1URL);

        if (videoIds.length >= 3) {
            //set thumbnail 2
            const cachedVideoInfo2 = getVideoInfoFromSession(videoIds[1]);
            initalizeThumbnails(cachedVideoInfo2, videoIds[1], setThumbnail2URL);
            //set thumbnail 3
            const cachedVideoInfo3 = getVideoInfoFromSession(videoIds[2]);
            initalizeThumbnails(cachedVideoInfo3, videoIds[2], setThumbnail3URL);
        }

    }, [learningRecordData]);


    const initalizeThumbnails = (cachedVideoInfo, videoId, setThumbnailURL) => {
        
        if (cachedVideoInfo) {
            setThumbnailURL(cachedVideoInfo.thumbUrl);
        } else {
            (async () => {
                const videoInfo = await fetchTitleAndThumb(videoId);
                if (videoInfo) {
                    setThumbnailURL(videoInfo.thumbUrl);
                    saveVideoInfoToSession(videoId, videoInfo);
                }
            })();
        }

    }


    const formatRecordTitle = (str) => {
        if (!str || typeof str !== 'string') {
            return str;
        }
        let result = str.charAt(0).toUpperCase() + str.slice(1);
        // truncate and append '...' if the string length exceeds 75
        if (result.length > 75) {
            result = result.substring(0, 72) + '...'; // 72 + 3 for the '...' = 75
        }

        return result;
    };

    const renderThumbnails = () => {
        return <>
                <div className="h-div-tile h-div-left">
                    <div>{" "}</div>
                    {getThumbnailURLImg(thumbnail2URL)}
                </div>
                <div className="h-div-tile h-div-center">
                    <div>{" "}</div>
                    {getThumbnailURLImg(thumbnail1URL)}
                </div>
                <div className="h-div-tile h-div-right">
                    <div>{" "}</div>
                    {getThumbnailURLImg(thumbnail3URL)}
                </div>
            </>
    };

    const getThumbnailURLImg = (thumbnailURL) => {
        if (thumbnailURL === "content") {
            return <img className="h-tile-img" src={require('./images/content-img.png')} alt="Youtube Resource" title="" />
            //return <div className="h-content-tile"></div>
        } else {
            return <img className="h-tile-img" src={thumbnailURL} alt="Youtube Resource" title="" />
        }
    }

    return <>
        
        {learningRecord && <>
            <div className="h-res-item-description">{formatRecordTitle(learningRecord.title)}</div>
            <div className="h-tile-img-div"> { renderThumbnails() } </div>
            <div className="res-item-bottom-box">
                <img className="auth-init-round-md" referrerPolicy="no-referrer" src={learningRecord.userPicture} />
                <div className="auth-name-in-grid">{learningRecord.userName}</div>
                <div className="res-item-thumbup"><ThumbUpAltOutlinedIcon fontSize="small" />&nbsp;{learningRecord.liked_users.length}</div>
            </div>
        </>}
    </>
}

export default LearningPostTile;

/*
const extractVideoId = (learningRecord) => {
    const learningSteps = learningRecord.steps;
    let vIDs = [];
    learningSteps.map((stepItem) => {
        if (stepItem.resources[0].type === "youtube") {
            const videoURL = stepItem.resources[0].content;
            const videoID = extractYouTubeVideoID(videoURL);
            vIDs.push(videoID);
        }
        else if (stepItem.resources[0].type === "content") {
            vIDs.push("content##");
        }
        else if (stepItem.resources[0].type === "webpage") {
            vIDs.push("webpage##");
        }
    });
    return vIDs;
};

const extractYouTubeVideoID = (link) => {
    if (/^[a-zA-Z0-9_-]{11}$/.test(link)) {
        return link;
    }
    const match = link.match(/(?:youtube\.com\/(?:watch\?v=|v\/|embed\/)|youtu\.be\/)([a-zA-Z0-9_-]{11})/);
    if (match) {
        return match[1];
    }
    return null;
};

const retrieveTitleAndThumbnail = async (videoId) => {

    if(videoId === "content##") {
        return "https://assets.entrepreneur.com/content/3x2/2000/1597865857-GettyImages-1151307397.jpg";
    } 
    else if (videoId === "webpage##") {
        return "https://ninjasites.com/images/blog/webpage-creation.png";
    }

    try {
        const response = await axios.get(`https://www.googleapis.com/youtube/v3/videos?key=${GOOGLE_API_KEY}&id=${videoId}&part=snippet`);
        const videoData = response.data.items[0].snippet;
        //console.log(videoData);
        if(videoData.thumbnails.medium.url) {
            return videoData.thumbnails.medium.url;
        } else {
            return null;
        }
    } catch (error) {
        console.error('Error:', error);
        return null;  
    }
};

const retrieveAllThumbnails = async (videoIds) => {
    let thumbnails = [];
    for (let i = 0; i < videoIds.length; i++) {
        try {
            const response = await axios.get(`https://www.googleapis.com/youtube/v3/videos?key=${GOOGLE_API_KEY}&id=${videoIds[i]}&part=snippet`);
            const videoData = response.data.items[0].snippet;
            if(videoData.thumbnails.medium.url)
                thumbnails.push(videoData.thumbnails.medium.url);
             
        } catch (error) {
            console.error('Error:', error); 
        }
    }
    return thumbnails;
};
*/