import React, { useState, useEffect, useContext } from 'react';

import axios from 'axios';
import uuid from 'react-uuid';
import { UserContext } from './UserProvider';
import profileImg from './images/profile.png';

import { FcGoogle } from 'react-icons/fc';

import appConfig from './config.json';
import LoadingAnimation from './LoadingAnimation';
import ConfirmModal from './ConfirmModal';

import VisibilityTwoToneIcon from '@mui/icons-material/VisibilityTwoTone';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';

import FavoriteBorderTwoToneIcon from '@mui/icons-material/FavoriteBorderTwoTone';
import ChatTwoToneIcon from '@mui/icons-material/ChatTwoTone';
import ForumTwoToneIcon from '@mui/icons-material/ForumTwoTone';
import ThumbUpTwoToneIcon from '@mui/icons-material/ThumbUpTwoTone';
import ThumbDownTwoToneIcon from '@mui/icons-material/ThumbDownTwoTone';
import QuestionMarkTwoToneIcon from '@mui/icons-material/QuestionMarkTwoTone';
import LogoutTwoToneIcon from '@mui/icons-material/LogoutTwoTone';

import './styles/profile.css';
import './styles/learning-post.css';
import RequestEditModal from './RequestEditModal';

function ProfilePage() {
    const context = useContext(UserContext);
    const [state, setState] = useState({
        questions: [],
        totalLikes: 0,
        isLoading: false,
        hasUnclearedNotification: false,
        userRecords: [],
        numOfUserRecords: 0,
        selectedRecordItem: { _id: '', userPicture: '', userName: '', title: '', step_content: [], resource_content: [], comments: [], steps: [] },
        isModalOpen: false,
        notifications: [],
    });

    const serverUrl = appConfig.SERVER_URL;
    //for editing request
    const [isEditingRequest, setIsEditingRequest] = useState(false);
    const [editRequestValidateMessage, setEditRequestValidateMessage] = useState("");

    const [requestItemToEdit, setRequestItemToEdit] = useState(null);
    const [requestItemToEditTitle, setRequestItemToEditTitle] = useState("");
    
    //for request react-modal box
    const [isConfirmRequestDeleteBoxOpen, setIsConfirmRequestDeleteBoxOpen] = useState(false);
    const confirmDeleteRequestTxt = "Are you sure you want to delete this request?";
    const [deleteRequestItem, setDeleteRequestItem] = useState(null);

    //for record react-modal box
    const [isConfirmRecordDeleteBoxOpen, setIsConfirmRecordDeleteBoxOpen] = useState(false);
    const confirmDeleteRecordTxt = "Are you sure you want to delete this shared item?";
    const [deleteRecordItem, setDeleteRecordItem] = useState(null);

    useEffect(() => {
        const init = async () => {
            await authUser();
            if (context.loginUserId) {
                const uid = context.loginUserId;
                await fetchUserRecords(uid);
                await fetchUserNotifications(uid);
                await fetchUserRequests(uid);
                await fetchHasUnclearedNotification(uid);
            }
        };
        init();
    }, [context.loginUserId]);

    const authUser = async () => {
        try {
            const url = `${serverUrl}protected`;
            console.log("url:", url);
            const { data } = await axios.get(url, { withCredentials: true });
            const responseUser = await fetch(`${serverUrl}user/${data.userEmail}`);
            if (!responseUser.ok) {
                throw new Error('HTTP error ' + responseUser.status);
            }
            const userData = await responseUser.json();
            if (userData.id !== 0) {
                context.setLoginUserId(userData.id);
                context.setLoginUserProfileImg(userData.profilePicture);
                context.setLoggedInUser(data.userName);
                context.setLoginUserEmail(data.userEmail);
            } else {
                // Assuming this means the user is new and needs to be added
                const payload = {
                    userGuid: uuid(),
                    username: data.userName,
                    email: data.userEmail,
                    picture: data.userPicture,
                };
                const addUserResponse = await fetch(`${serverUrl}users/add`, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(payload)
                });
                if (!addUserResponse.ok) {
                    throw new Error('Failed to add user.');
                }
                const addedUserData = await addUserResponse.json();
                console.log("addedUserData is below:");
                console.log(addedUserData);
                context.setLoginUserId(addedUserData.userId);
                context.setLoginUserProfileImg(data.userPicture);
                context.setLoggedInUser(data.userName);
                context.setLoginUserEmail(data.userEmail);
            }
        } catch (err) {
            console.log("Error during authentication: ", err);
        }
    };

    const fetchUserRecords = async (uId) => {
        const page = 1;
        const fetchSize = 100;
        try {
            const response = await fetch(`/records/${uId}?page=${page}&limit=${fetchSize}`);
            if (!response.ok) {
                throw new Error(`Error: Received status code ${response.status}`);
            }
            const result = await response.json();
            setState(prevState => ({
                ...prevState,
                userRecords: result.data,
                numOfUserRecords: result.total,
            }));
        } catch (error) {
            console.log('an error occurred when calling fetchRecords()');
            console.log(error);
        }
    };

    const fetchUserNotifications = async (uId) => {
        try {
            const page = 1;
            const limit = 100;
            const url = `${serverUrl}notificationDetails/${uId}?page=${page}&limit=${limit}`;
            //console.log("fetchUserNotifications -- ");
            //console.log(url);
            const response = await axios.get(url);
            const notificationsList = response.data;
    
            setState(prevState => ({
                ...prevState,
                notifications: notificationsList,
            }));

        } catch (error) {
            if (error.response) {
                console.log(`Error: Received status code ${error.response.status}`);
            } else if (error.request) {
                console.log('Error: No response received when making the request');
            } else {
                console.log('Error: Something went wrong in setting up the request');
            }
            console.log('An error occurred when calling fetchUserNotifications():', error.message);
        }
    };

    const fetchUserRequests = async (uId) => {
        const response = await fetch(`${serverUrl}requests/byuser/${uId}`);
        if (!response.ok) {
            const message = `An error occurred: ${response.statusText}`;
            console.error(message);
            return;
        }
        const requests = await response.json();
        setState(prevState => ({
            ...prevState,
            questions: requests.data,
        }));
    };

    const fetchHasUnclearedNotification = async (uId) => {
        try {
            const response = await axios.get(`${serverUrl}notifications/${uId}/hasUnclearedNotification`);
            setState(prevState => ({
                ...prevState,
                hasUnclearedNotification: response.data.hasUnclearedNotification,
            }));
        } catch (error) {
            console.error('Error fetching uncleared notifications:', error);
        }
    };

    const onGoogleAuth = () => {
        console.log("googleAuth function starting...");
        window.open(`${serverUrl}auth/google`, "_self");
    };

    const onLogout = () => {
        window.open(`${serverUrl}logout`, "_self");
        sessionStorage.removeItem('loggedInUser');
        sessionStorage.removeItem('loginUserId');
        sessionStorage.removeItem('loginUserEmail');
        sessionStorage.removeItem('loginUserProfileImg');
    };

    const onWhatsNewTabClick = async () => {
        await onClearAllNotifications();
    };
    
    const onClearAllNotifications = async () => {
        try {
            if (state.notifications.length === 0) {
                return;
            }
            const ids = state.notifications
                .filter(notification => !notification.isCleared)
                .map(notification => notification._id);
    
            if (ids.length === 0) {
                return;
            }
            const response = await axios.post(`${serverUrl}notifications/clearMutiNotifications`, { ids });
            if (response.data.success) {
                setState(prevState => ({
                    ...prevState,
                    hasUnclearedNotification: false, // clear the red dot from tab
                }));
                context.setProfileHasNewNotification(false); // clear the red dot from profile assuming there's a context method to handle this
            } else {
                console.error('Failed to clear notifications');
            }
        } catch (error) {
            console.error('Error clearing notifications:', error);
        }
    };

    const RenderLoginUI = () => {
        return (
            <div class="google-login-div">
                <div className="profile-image-div justify-content-center">
                    <img className="profile-img" referrerpolicy="no-referrer" src={profileImg} />
                    <button className="login-google-btn" onClick={onGoogleAuth}><FcGoogle className="login-google-sign" /> Sign in with Google</button>
                    <div className="profile-nonlogin-content">Sign in to see how many people find your experience helpful!</div>
                </div>
                <div className="profile-account-create-content">If you are a new user, we register an account for you automatically</div>
            </div>
        );
    };

    const getNotificationMessage = (notiItem) => {
        const nType = notiItem.type;
        switch (nType) {
            case "record-new-comment":
                return <><ChatTwoToneIcon fontSize='small'/> {notiItem.userName} posted a new comment to your post: <b>{notiItem.recordTitle}</b></>;
            case "comment-new-reply":
                return <><ForumTwoToneIcon fontSize='small'/> {notiItem.userName} replied to your comment at post: <b>{notiItem.recordTitle}</b></>;
            case "comment-new-upvote":
                return <><ThumbUpTwoToneIcon fontSize='small'/> {notiItem.userName} upvoted your comment at post: <b>{notiItem.recordTitle}</b></>;
            case "comment-new-downvote":
                return <><ThumbDownTwoToneIcon fontSize='small'/> {notiItem.userName} downvoted your comment at post: <b>{notiItem.recordTitle}</b></>;
            case "record-new-like":
                return <><FavoriteBorderTwoToneIcon fontSize='small'/> {notiItem.userName} liked your post: <b>{notiItem.recordTitle}</b></>;
            default:
                return <><QuestionMarkTwoToneIcon fontSize='small'/>{" Unknown notification"}</>;
        }
    };

    const onSelectNotificationItem = (notiItem) => {
        window.location.href = `/home?rid=${notiItem.recordId}`;
    };

    const loadMoreRecords = async () => {
        console.log("load more record..")
    };

    const getProfileUIMessage = (numOfLikes) => {
        let textToDisplay = "";
        if (numOfLikes === 0) {
            textToDisplay = 'Start sharing your valuable experience to inspire more people.';
        } else if (numOfLikes === 1) {
            textToDisplay = '1 person finds your experience helpful, keep it up!';
        } else {
            textToDisplay = `${numOfLikes} people find your experience helpful.`;
        }
        return textToDisplay;
    };

    const onSelectRequestItem = (reqItem) => {
        console.log(reqItem);
    };

    const onClickEditRequestItem = (reqItem) => {
        //console.log(reqItem);
        setRequestItemToEdit(reqItem);
        /*
        setRequestItemToEditTitle(reqItem.question_content);
        const rawCommentContent = reqItem.note;
        const contentStateToEdit = convertFromRaw(JSON.parse(rawCommentContent));
        const newEditorState = EditorState.createWithContent(contentStateToEdit);
        setEditorState(newEditorState);*/
  
        setIsEditingRequest(true);
    };

    const onRequestItemEditTitleChange = (e) => {
        setRequestItemToEditTitle(e.target.value);
        e.target.style.height = "auto";
        e.target.style.height = `${e.target.scrollHeight}px`;
    };
  
    const onEditingRequestCancelBtnClick = () => {
        setIsEditingRequest(false);
        //REVIEW: need some other cleanups
    };

    const onEditingRequestSaveBtnClick = () => {
        if (requestItemToEditTitle === "") {
          //show validation msg, then return
          setEditRequestValidateMessage("The question cannot be empty.");
          setTimeout(()=>{ setEditRequestValidateMessage("");}, 5001);
          return;
        }
  
        console.log("saved");
        //REVIEW: add logic here
        setIsEditingRequest(false);
  
    };

    const onRequestEditModalClose = () => {
        setIsEditingRequest(false);
    };

    const onRequestEditModalUpdated = async () => {
        await fetchUserRequests(context.loginUserId);
    }

    const hideEditRequestValidateMessage = () => {
        setEditRequestValidateMessage("");
    };


    const onDeleteRequestBtnClick= (reqItem) => {
        const foundItem = reqItem;
        if (foundItem) {
          setDeleteRequestItem(foundItem);
          setIsConfirmRequestDeleteBoxOpen(true);
        } else {
            console.error(`No item found with ID: ${reqItem._id}`);
            setDeleteRequestItem(null);
        }
    };

    const onRequestDeletionModalBoxConfirm = async () => {
        await deleteSelectRequest();
        setIsConfirmRequestDeleteBoxOpen(false);
        setDeleteRequestItem(null);
        await fetchUserRequests(context.loginUserId);
    };

    
    const deleteSelectRequest = async () => {
        try {
          const deleteId = deleteRequestItem._id;
          const response = await fetch(`/requests/${deleteId}/delete`, {
            method: 'DELETE',
          });
  
          if (!response.ok)
            throw new Error(`HTTP error ${response.status}`);
  
        } catch (error) {
          console.error('Failed to delete RequestItems:', error);
        }
    };

    const onRequestDeletionModalBoxCancel = () => {
        setIsConfirmRequestDeleteBoxOpen(false);
        setDeleteRequestItem(null);
    };

    const onSelectRecordItem = (recordItem) => {
        console.log(recordItem);
    };

    const onClickViewRequestItem = (recordItem) => {
        window.open(`/home?rid=${recordItem._id}`, '_blank');
    }

    const onClickEditRecordItem = (recordItem) => {
        window.open(`/browsing?editId=${recordItem._id}`, '_blank');
    };


    const onDeleteRecordItem = (recordItem) => {
        const foundItem = recordItem;
        if (foundItem) {
            setDeleteRecordItem(foundItem);
            setIsConfirmRecordDeleteBoxOpen(true);
        } else {
            console.error(`No item found with ID: ${recordItem._id}`);
            setDeleteRecordItem(null);
        }
    };

    const onRecordDeletionModalBoxConfirm = async () => {
        await deleteSelectRecord();
        setIsConfirmRecordDeleteBoxOpen(false);
        setDeleteRecordItem(null);
        await fetchUserRecords(context.loginUserId);
    };


    const deleteSelectRecord = async () => {
        try {

            const deleteId = deleteRecordItem._id;
            const response = await fetch(`/records/${deleteId}/delete`, {
                method: 'DELETE',
            });

            if (!response.ok)
                throw new Error(`HTTP error ${response.status}`);

        } catch (error) {
            console.error('Failed to delete Record:', error);
        }
    };

    const onRecordDeletionModalBoxCancel = () => {
        setIsConfirmRecordDeleteBoxOpen(false);
        setDeleteRecordItem(null);
    };




    return (
        <>
            {!context.loginUserId ? (RenderLoginUI()) : (<>
                <div className="container panel-container justify-content-center">
                    {state.isLoading ? (<LoadingAnimation />) : null}
                    <div className="profile-image-div justify-content-center">
                        <img className="profile-img" alt="profile" referrerpolicy="no-referrer" src={context.loginUserProfileImg} />
                    </div>
                    <div className="profile-name-div justify-content-center">
                        <div className="profile-username"><div className='profile-username-txt'>{context.loggedInUser} </div><button title="logout" className="profile-item-btn profile-logout-btn" onClick={onLogout}><LogoutTwoToneIcon fontSize='small' /></button></div>
                        <div className="profile-email">{context.loginUserEmail}</div>
                    </div>

                    <ul class="nav nav-tabs justify-content-center" id="myTab" role="tablist">
                        <li class="nav-item" role="presentation">
                            <button class="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home-tab-pane" type="button" role="tab" aria-controls="home-tab-pane" aria-selected="true">View My Requests ... coming soon</button>
                        </li>
                        <li class="nav-item" role="presentation">
                            <button class="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile-tab-pane" type="button" role="tab" aria-controls="profile-tab-pane" aria-selected="false">View My Shares</button>
                        </li>
                        <li class="nav-item" role="presentation">
                            <button class="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#news-tab-pane" type="button" role="tab" aria-controls="profile-tab-pane" aria-selected="false" onClick={onWhatsNewTabClick}>What's New?
                                {state.hasUnclearedNotification && <span className="red-dot"></span>}
                            </button>
                        </li>
                    </ul>

                    <div class="tab-content" id="myTabContent">
                        {/* 'View My Requests' - tab */}
                        <div class="tab-pane fade show active" id="home-tab-pane" role="tabpanel" aria-labelledby="home-tab" tabIndex="0">
                            <div class="profile-request-content">
                                <div className="modal-body">
                                    <ul class="list-group list-group-flush">
                                        {state.questions.map((reqItem, index) => (
                                            <li className="list-group-item" key={index}>
                                                <div className='request-item-li-div'>
                                                    <div className="request-item-div" data-rid={reqItem._id} onClick={() => onSelectRequestItem(reqItem)}>{reqItem.question_content}</div>
                                                    <div className='request-item-actions'>
                                                        <button className='profile-item-btn' onClick={() => onClickEditRequestItem(reqItem)} title='Edit'><EditTwoToneIcon fontSize="small" /></button>
                                                        <button className='profile-item-btn' data-rid={reqItem._id} onClick={() => onDeleteRequestBtnClick(reqItem)} title='Remove'><DeleteTwoToneIcon fontSize="small"/></button>
                                                    </div>
                                                </div>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>

                        {/* 'View My Shares' - tab */}
                        <div class="tab-pane fade" id="profile-tab-pane" role="tabpanel" aria-labelledby="profile-tab" tabIndex="0">
                            <div class="profile-request-content">
                                {/*<div className="profile-msg-div"><b>{context.loggedInUser}! {getProfileUIMessage(state.totalLikes)}</b></div>*/}
                                <ul class="list-group list-group-flush">
                                    {state.userRecords.map((recordItem, index) => (
                                        <li className="list-group-item" key={index}>
                                            <div className='request-item-li-div'>
                                                <div className="request-item-div" data-rid={recordItem._id} onClick={() => onSelectRecordItem(recordItem)}>{recordItem.title}</div>
                                                <div className='record-item-actions'>
                                                    <button className='profile-item-btn' onClick={() => onClickViewRequestItem(recordItem)} title='View'><VisibilityTwoToneIcon fontSize="small"/></button>
                                                    <button className='profile-item-btn' onClick={() => onClickEditRecordItem(recordItem)} title='Edit'><EditTwoToneIcon fontSize="small" /></button>
                                                    <button className='profile-item-btn' data-rid={recordItem._id} onClick={() => onDeleteRecordItem(recordItem)} title='Remove'><DeleteTwoToneIcon fontSize="small"/></button>
                                                </div>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>

                        {/* 'What's New?' - tab */}
                        <div class="tab-pane fade" id="news-tab-pane" role="tabpanel" aria-labelledby="news-tab" tabIndex="0">
                            <div class="profile-request-content">
                                <ul class="list-group list-group-flush">
                                    {state.notifications.map((notiItem, index) => (
                                        <li className="list-group-item" key={index}>
                                            <div className="notification-item-div" data-rid={notiItem._id} onClick={() => onSelectNotificationItem(notiItem)}>{getNotificationMessage(notiItem)}</div>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                {isEditingRequest && <RequestEditModal reqItem={requestItemToEdit} onClose={onRequestEditModalClose} onUpdate={onRequestEditModalUpdated} />}
                {/* modal box for confirm request delete */}
                {isConfirmRequestDeleteBoxOpen && <ConfirmModal onConfirm={onRequestDeletionModalBoxConfirm} onCancel={onRequestDeletionModalBoxCancel} contentText={confirmDeleteRequestTxt} />}
                {/* modal box for confirm record delete */}
                {isConfirmRecordDeleteBoxOpen && <ConfirmModal onConfirm={onRecordDeletionModalBoxConfirm} onCancel={onRecordDeletionModalBoxCancel} contentText={confirmDeleteRecordTxt} />}

            </>)}
        </>
    );
}

export default ProfilePage;