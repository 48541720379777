/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import axios from "axios";
import { Masonry, useInfiniteLoader } from "masonic";

import LearningPostTile from "./LearningPostTile";
import LearningPostBox from "./LearningPostBox";
import RequestListModalBox from "./RequestListModalBox";
import NewRequestModalBox from "./NewRequestModalBox";


//icons
import AutoStoriesTwoToneIcon from '@mui/icons-material/AutoStoriesTwoTone';
import TipsAndUpdatesTwoToneIcon from '@mui/icons-material/TipsAndUpdatesTwoTone';
import ChatTwoToneIcon from '@mui/icons-material/ChatTwoTone';
import FiberNewTwoToneIcon from '@mui/icons-material/FiberNewTwoTone';
import LocalFireDepartmentTwoToneIcon from '@mui/icons-material/LocalFireDepartmentTwoTone';
import FavoriteTwoToneIcon from '@mui/icons-material/FavoriteTwoTone';

import CancelTwoToneIcon from '@mui/icons-material/CancelTwoTone';


//styles
import "./styles/browsing.css";
import "./styles/request-btn.css";
import "./styles/square.css";
import "./styles/request.css";
import "./styles/home-page.css";
import "./styles/learning-post.css";

import { UserContext } from "./UserProvider";
import RecordDetailsModal from './RecordDetailsModal';


const HomePage = (props) => {

    const location = useLocation();
    const { loginUserId } = useContext(UserContext);

    const [records, setRecords] = useState([]);
    const [sortby, setSortby ] = useState('new');
    const [page, setPage] = useState(1);
    const [isLoadingRecords, setIsLoadingRecords] = useState(true);
    const [hasMoreRecords, setHasMoreRecords] = useState(true);
    const [selectedRecord, setSelectedRecord] = useState({ _id: "", userPicture: "", userName: "", title: "", step_content: [], resource_content: [], comments: [], steps: [] });
    const [showRecordDetailBox, setShowRecordDetailBox] = useState(false);
    const [showChat, setShowChat] = useState(false);

    const [searchTerm, setSearchTerm] = useState("");

    const getColumnWidth = () => {
        const screenWidth = window.innerWidth;
        if (screenWidth < 768) { // Mobile devices
            return 168; // Adjust as needed for mobile
        } else { // Desktop
            return 240; // Adjust as needed for desktop
        }
    }

    const getColumnGutter = () => {
        const screenWidth = window.innerWidth;
        if (screenWidth < 768) { // Mobile devices
            return 8; // Adjust as needed for mobile
        } else { // Desktop
            return 15; // Adjust as needed for desktop
        }
    }

    const [columnWidth, setColumnWidth] = useState(getColumnWidth());
    const [columnGutter, setColumnGutter] = useState(getColumnGutter());

    const loadInitialRecords = async () => {
        const fetchSize = 99;
        try {
            const result = await axios.get(`/records?page=${page}&size=${fetchSize}&sortby=${sortby}`);
            if (result.status !== 200) {
                throw new Error(`Error: Received status code ${result.status}`);
            }
            setRecords(result.data.data);
            setIsLoadingRecords(false);

        } catch (error) {
            console.log(error.message);
        } finally {
            //this.setState({ loading: false });
        }
    };

    const loadInitialSearchRecords = async (searchKey) => {

        console.log(searchKey);

        const fetchSize = 99;
        try {
            const result = await axios.get(`/searchRecords?page=${page}&size=${fetchSize}&sortby=${sortby}&search=${searchKey}`);
            if (result.status !== 200) {
                throw new Error(`Error: Received status code ${result.status}`);
            }
            setRecords(result.data.data);
            setIsLoadingRecords(false);

        } catch (error) {
            console.log(error.message);
        } finally {
            //this.setState({ loading: false });
        }
    };


    const fetchSortedRecords = async (key) => {
        const page = 1 
        const fetchSize = 99;
        try {
            const result = await axios.get(`/records?page=${page}&size=${fetchSize}&sortby=${key}`);
            if (result.status !== 200) {
                throw new Error(`Error: Received status code ${result.status}`);
            }
            setRecords(result.data.data);
            setSortby(key);
        } catch (error) {
            console.log(error.message);
        } finally {
            //this.setState({ loading: false });
        }
    };

    const onSortByBtnClick = (key) => {
        fetchSortedRecords(key);
    };

    const loadMoreRecords = async () => {
        //if (loading) return;

        //setIsLoadingRecords(true);
        // Fetch more records from the web service
        // Append them to the current records
        // Update 'hasMore' if there are no more records to load
        //setIsLoadingRecords(false);
    };


    const renderRecordTile = (record, index) => {
        return <>
            <div className="h-tile" key={index} onClick={() => onOpenItemModal(record.data)}>
                <LearningPostTile learningRecordData={record.data} />
            </div>
        </>
    };

    const onOpenItemModal = (item) => {
        setSelectedRecord(item);
        window.history.pushState(null, null, `?rid=${item._id}`);
        openRecordDetailBox();
    };

    const closeRecordDetailsBox =() => {
        document.body.classList.remove('h-no-scroll');
        setShowRecordDetailBox(false);
        setSelectedRecord({ _id: "", userPicture: "", userName: "", title: "", step_content: [], resource_content: [], comments: [], steps: [] });
        window.history.replaceState(null, null, window.location.pathname);
    };

    const openRecordDetailBox =() => {
        document.body.classList.add('h-no-scroll');
        setShowRecordDetailBox(true);
    };

    const onRemoveSearchTermBtnClick = () => {
        window.location.href = '/home';
    };

   /* useEffect(async () => {
        loadInitialRecords();
    }, []);
    */

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const searchKey = params.get('search');
        if (searchKey) {
            setSearchTerm(searchKey);
            loadInitialSearchRecords(searchKey);
        } else {
            setSearchTerm("");
            loadInitialRecords();
        }
    }, [location.search]);

    useEffect(() => {
        const handleResize = () => {
            setColumnWidth(getColumnWidth());
            setColumnGutter(getColumnGutter());
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        if(isLoadingRecords)
            return;

        const params = new URLSearchParams(location.search);
        const recordId = params.get('rid');
        if (recordId) {
            const record = records.find((r) => r._id === recordId);
            if (record) {
                onOpenItemModal(record);
            }
        }
    }, [location.search, records, isLoadingRecords]);

    return (
        <>
            <div className='h-top-action-div'>
                <div className="h-sort-btns-div">
                    <button className='request-list-sort-btn' onClick={()=>onSortByBtnClick("new")}><FiberNewTwoToneIcon fontSize='small' /> New</button>
                    <button className='request-list-sort-btn' onClick={()=>onSortByBtnClick("hot")}><LocalFireDepartmentTwoToneIcon fontSize='small'/> Hot</button>
                    <button className='request-list-sort-btn' onClick={()=>onSortByBtnClick("liked")}><FavoriteTwoToneIcon fontSize='small'/> Most Liked</button>
                    { searchTerm && (
                        <button className='request-list-sort-btn' onClick={()=>onRemoveSearchTermBtnClick()}>Search: {searchTerm} <CancelTwoToneIcon fontSize='small'/></button>
                    )}
                    
                </div>
                {/* <div className="h-function-btns-div">
                    { loginUserId && <><button className="request-list-sort-btn" data-bs-toggle="modal" data-bs-target={`#newRequestModal`}> <TipsAndUpdatesTwoToneIcon fontSize='small'/> Ask</button></>}
                    <button className="request-list-sort-btn" data-bs-toggle="modal" data-bs-target={`#requestListModal`}> <AutoStoriesTwoToneIcon fontSize='small'/> View Requests</button>
                    { showChat && <button className='request-list-sort-btn' ><ChatTwoToneIcon fontSize='small'/> Chat</button> }
                </div> */}
            </div>
            {/* the main squares container - contains all the square items */}
            <main className='h-container'>
                <div className='h-masonic'>
                    <Masonry onRender={loadMoreRecords} render={renderRecordTile} items={records} columnGutter={columnGutter} columnWidth={columnWidth} overscanBy={1.25} />
                </div>
            </main>
            {/* the modal box to display squre item details */}
            {showRecordDetailBox && (
                <div className="h-record-detail-bkg">
                    <RecordDetailsModal recordItem={selectedRecord} onClose={closeRecordDetailsBox} />
                </div>
            )}
            {/* the modal box to display when clicks 'view requests button on top' */}
            <RequestListModalBox />
            {/* the modal box to display when clicks 'new requests button - LOGIN REQUIRED'*/}
            { loginUserId && <> <NewRequestModalBox /> </>}

            
        </>
    );
};

export default HomePage;

