import React, { useEffect, useState, useContext, useRef  } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import axios from "axios";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import ConfirmModal from './ConfirmModal';
import SubmitAnimation from './SubmitAnimation';

import { AiOutlineYoutube, AiOutlineLink, AiOutlineFileText, AiOutlineCloseCircle, AiOutlineEdit, AiOutlineEye, AiOutlineFileSearch } from "react-icons/ai";
import { IoImageOutline } from "react-icons/io5";
import { RiRefreshLine } from "react-icons/ri";
import { BsTrash, BsSend, BsPlusCircle } from "react-icons/bs";

import { UserContext } from "./UserProvider";
import { ObjectId } from 'bson';
import "./styles/learning-page.css";

import PreviewModal from './PreviewModal';

import ImageUploader from './ImageUploader';


// Define the StepItem component
const StepItem = ({
    stepItem,
    index,
    handleStepTitleChange,
    handleStepResChange,
    onStepResTypeBtnClick,
    activeResTypeBtnsIndex,
    onDeleteStepBtnClick,
    onStepItemResTypeChange,
    getStepItemResTypeBtnStyle,
}) => {
    const [imageData, setImageData] = useState(null);

    const resItem = stepItem.resourceItem[0];

    useEffect(() => {
        const fetchImageData = async () => {
            if (resItem.type === 'image' && resItem.content) {
                try {
                    const response = await axios.get(`/image/${resItem.content}`);
                    const data = response.data;
                    setImageData(`data:${data.contentType};base64,${data.image}`);
                } catch (error) {
                    console.error("Error fetching image data:", error);
                }
            }
        };
        fetchImageData();
    }, [resItem]);

    return (
        <>
            <div className='learning-step-name'>
                <textarea
                    className='learning-step-name-item-input auto-adjust'
                    type='text'
                    value={stepItem.titleContent}
                    onChange={e => handleStepTitleChange(index, e)}
                />
            </div>
            <div className='learning-step-btns' data-rid={stepItem.id}>
                <button className='side-s-btn' onClick={() => onStepResTypeBtnClick(index)}>
                    <AiOutlineFileSearch />
                </button>
            </div>
            {activeResTypeBtnsIndex !== index ? (
                <>
                    <div className='learning-step-res'>
                        <div className='learning-step-res-item'>
                            {resItem.type === 'image' ? (
                                imageData ? (
                                    <img src={imageData} alt="Resource" className="learning-step-image-item display-img" />
                                ) : (
                                    <p>Loading image...</p>
                                )
                            ) : (
                                <textarea
                                    className='learning-step-name-item-input auto-adjust'
                                    type='text'
                                    value={resItem.content}
                                    onChange={e => handleStepResChange(index, e)}
                                />
                            )}
                        </div>
                    </div>
                    <div className='learning-step-btns' data-rid={stepItem.id}>
                        <button className='side-s-btn side-btn-hovers' onClick={e => onDeleteStepBtnClick(e)}>
                            <BsTrash />
                        </button>
                    </div>
                </>
            ) : (
                <>
                    <div className='learning-step-add-res'>
                        <div className='learning-step-res-type'>
                            <button
                                style={getStepItemResTypeBtnStyle(index, 'content')}
                                onClick={() => onStepItemResTypeChange(index, 'content')}
                            >
                                <AiOutlineFileText /> Text
                            </button>
                            <button
                                style={getStepItemResTypeBtnStyle(index, 'image')}
                                onClick={() => onStepItemResTypeChange(index, 'image')}
                            >
                                <IoImageOutline /> Image
                            </button>
                            <button
                                style={getStepItemResTypeBtnStyle(index, 'youtube')}
                                onClick={() => onStepItemResTypeChange(index, 'youtube')}
                            >
                                <AiOutlineYoutube /> YouTube
                            </button>
                            <button
                                style={getStepItemResTypeBtnStyle(index, 'webpage')}
                                onClick={() => onStepItemResTypeChange(index, 'webpage')}
                            >
                                <AiOutlineLink /> Webpage
                            </button>
                        </div>
                    </div>
                    <div className='learning-step-btns' data-rid={stepItem.id}>
                        {/* Additional buttons if needed */}
                    </div>
                </>
            )}
        </>
    );
};


const LearningRecordPage = () => {

    const GOOGLE_API_KEY = "AIzaSyA25EgOi9tARCwrlwDA0FoMkgtJ0BWQS2U";

    const titlePlaceholder = "What experience you would like to share?";
    const maxTitleCharNum = 210;
    const stepTitlePlaceholder = "What is this part about?";
    const maxStepTitleCharNum = 210;
    const resTitlePlaceholder = "What is the content of the resource?";
    const maxResTitleCharNum = 210;
    
    const resURLPlaceholder = "Enter the webpage URL related to this";
    const resYTURLPlaceholder = "Enter the YouTube URL or video ID related to this";
    const resContentPlaceholder = "Enter the text content related to this";

    const [addResTextAreaPlaceholder, setAddResTextAreaPlaceholder] = useState(resYTURLPlaceholder);

    const confirmDeleteStepTxt = "Are you sure you want to remove this?";
    const learningNoStepTxt = "Please start by designing a step that includes a resource for your experience..";
    const confirmPublishTxt = "Are you sure you ready to publish this experience?";
    const confirmUpdateTxt = "Are you sure you want to make current update to this experience?";
    const confirmCancelStepTxt = "This will delete all of your previously created steps. Are you sure you want to do this?";
    const confirmResetStepTxt = "This will reverse all of your previously edited steps. Are you sure you want to do this?";

    const [cancelResetTxt, setCancelResetTxt] = useState(confirmCancelStepTxt);
    const [createUpdateTxt, setCreateUpdateTxt] = useState(confirmPublishTxt);

    const [learningTitle, setLearningTitle] = useState('');
    const [isEditMode, setIsEditMode] = useState(false);
    const [isEditingLearningId, setIsEditingLearningId] = useState(null);

    const [learningSteps, setLearningSteps] = useState([]);

    const [isAddingContentResItem, setIsAddingContentResItem] = useState(false);
    const [activeResTypeBtn, setActiveResTypeBtn] = useState('content');

    const [resURLContentToAdd, setResURLContentToAdd] = useState("");
    const [stepTitleContentToAdd, setStepTitleContentToAdd] = useState("");

    const [resImageBuffToAdd, setResImageBuffToAdd] = useState("");
    const [resImageToAdd, setResImageToAdd] = useState(null);

    const [isShowValidationMsg, setIsShowValidationMsg] = useState(false);
    const [validationMessage, setValidationMessage] = useState("");

    const [isConfirmDeleteBoxOpen, setIsConfirmDeleteBoxOpen] = useState(false);
    const [toDeleteStepID, setToDeleteStepID] = useState(null);

    const [isConfirmPublishBoxOpen, setIsConfirmPublishBoxOpen] = useState(false);
    const [isConfirmCancelBoxOpen, setIsConfirmCancelBoxOpen] = useState(false);
    const [isShowSubmitAnimation, setIsShowSubmitAnimation] = useState(false);

    const [isUploadingImage, setIsUploadingImage] = useState(false);

    // create mode
    const [isCreateMode, setIsCreateMode] = useState(false);

    const stepTitleTextRef = useRef(null);
    const stepTitleContentTextRef = useRef(null);
    const resourceURLRef = useRef(null);

    const {loggedInUser, loginUserId, loginUserProfileImg} = useContext(UserContext);

    const [isShowAddStepDiv, setIsShowAddStepDiv] = useState(false);
    const [activeResTypeBtnsIndex, setActiveResTypeBtnsIndex] = useState(null);

    const navigate = useNavigate();

    const defaultResBtnStyle = {
        backgroundColor: 'white',
        cursor: 'pointer'
    };
    
    const activeResBtnStyle = {
        ...defaultResBtnStyle,
        backgroundColor: '#ddd' // Slightly dark background for active button
    };

    useEffect(() => {
        if (!loginUserId) {
            navigate("/profile"); // Redirect to /profile if not logged in
        }
    }, [loginUserId, navigate]); 

    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);
        const editId = searchParams.get('editId');
        const isCreateMode = searchParams.get('isCreate');
        if (editId) {
            fetchLearningRecord(editId);
            setIsEditingLearningId(editId);
            setIsEditMode(true);
        } else if (isCreateMode) {
            setIsCreateMode(true);
        }

    }, []);


    useEffect(() => {
        // After the component has rendered/updated, adjust the height of each textarea
        document.querySelectorAll('.auto-adjust').forEach(textarea => {
            adjustTextAreaHeight(textarea);
        });
    });

    const getResTypeBtnStyle = (buttonName) => {
        return activeResTypeBtn === buttonName ? activeResBtnStyle : defaultResBtnStyle;
    };

    const getStepItemResTypeBtnStyle = (index, type) => {
        return learningSteps[index].resourceItem[0].type === type ? activeResBtnStyle : defaultResBtnStyle;
    };

    /**
     * Handle when resource type button is clicked - change btn and input styles
     */
    const onResTypeBtnClick = (buttonName) => {

        if (buttonName === "image") {
            setIsUploadingImage(true);
        } else {
            setIsUploadingImage(false);
        }

        setResURLContentToAdd("");
        setActiveResTypeBtn(buttonName);
        const textareaPlaceholder = getTextAreaPlaceholderContent(buttonName);
        setAddResTextAreaPlaceholder(textareaPlaceholder);
        setIsAddingContentResItem(true);
    };

    /**
     * This function returns the corresponding placeholder for the textarea 
     * when select different type of resources
     * @param {*} buttonName 
     * @returns 
     */
    const getTextAreaPlaceholderContent = (buttonName) => {
        if (buttonName === "youtube") {
            return resYTURLPlaceholder;
        } else if (buttonName === "webpage") {
            return resURLPlaceholder;
        } else {
            return resContentPlaceholder;
        }
    };


    const handleInputChange = (e) => {
        onAddStepInputChange(e, 'resourceURL');
        autoResize(e);
    };
    
    const autoResize = (e) => {
        const textarea = e.target;
        textarea.style.height = 'auto'; // Reset height to auto to calculate new height
        textarea.style.height = `${textarea.scrollHeight}px`; // Set height based on scrollHeight
    };
    

    const onResMenuBtnClick = () => { 
        setIsAddingContentResItem(!isAddingContentResItem);
    };
    
    /**
     * when learning title textareas changes - at the very top
     */
    const onShareTitleTextChange = (e) => {
        setLearningTitle(e.target.value);
        e.target.style.height = "auto";
        e.target.style.height = `${e.target.scrollHeight}px`;
    };

    /**
     * when add step input textareas changes
     */
    const onAddStepInputChange = (e, fieldToUpdate) => {
        const value = e.target.value;
        switch (fieldToUpdate) {
          case 'stepTitle':
            setStepTitleContentToAdd(value);
            break;
          case 'resourceURL':
            setResURLContentToAdd(value);
            break;
          default:
            break;
        }
    };

    /**
     * when a step item is dragged and dropped at somewhere
     */
    const onStepItemDragEnd = (result) => {
        if (!result.destination) {
            return;
        }

        const reorderedItems = reorder(
            learningSteps,
            result.source.index,
            result.destination.index
        );

        setLearningSteps(reorderedItems);
    };

    /**
     * validate the current added step item content
     */
    const validateStepItem = () => {
        if(stepTitleContentToAdd === "") {
            setValidationMessage("Error: It cannot be empty. Please enter something :) "); 
            setIsShowValidationMsg(true);
            if (stepTitleContentTextRef.current) {
                stepTitleContentTextRef.current.focus();
            }
            return false;
        }
        return true;
    };

    /**
     * validate the current added resource item
     */
    const validateResource = async () => {
        
        if(resURLContentToAdd === "") {
            setValidationMessage("Error: Resource cannot be empty. Please enter its content"); 
            setIsShowValidationMsg(true);
            if (resourceURLRef.current) {
                resourceURLRef.current.focus();
            }
            return false;
        }
        if(activeResTypeBtn === "youtube") {
            const ytID = extractYouTubeVideoID(resURLContentToAdd);
            //console.log(`ytID:${ytID}`);
            if(!ytID) {
                setValidationMessage("Error: The resource's Youtube link or video ID is not valid. (https://www.youtube.com/watch?v=xxxxxxxxxxx or xxxxxxxxxxx) "); 
                setIsShowValidationMsg(true);
                if (resourceURLRef.current) {
                    resourceURLRef.current.focus();
                }
                return false;
            } else {
                //validate the id
                const videoResult = await retrieveTitleAndThumbnail(ytID);
                //console.log(`videoResult:${videoResult}`);
                if(videoResult) {
                    return true;
                } 
                setValidationMessage("Error: The resource's Youtube ID is not valid. (please verify the video) "); 
                setIsShowValidationMsg(true);
                if (resourceURLRef.current){
                    resourceURLRef.current.focus();
                }
                return false;
            }
        }
        return true;
    };

    const retrieveTitleAndThumbnail = async (videoId) => {
        try {
            const response = await axios.get(`https://www.googleapis.com/youtube/v3/videos?key=${GOOGLE_API_KEY}&id=${videoId}&part=snippet`);
            const videoData = response.data.items[0].snippet;
            if (!videoData) {
                return false;
            } else {
                return true;
            }
        } catch (error) {
            console.error('Error:', error);
            return false;  // Return false if there's an error (e.g., invalid videoId)
        }
    };

    const extractYouTubeVideoID = (link) => {
        if (/^[a-zA-Z0-9_-]{11}$/.test(link)) {
            return link;
        }
        const match = link.match(/(?:youtube\.com\/(?:watch\?v=|v\/|embed\/)|youtu\.be\/)([a-zA-Z0-9_-]{11})/);
        if (match) {
            return match[1];
        }
        return null;
    };

    /**
     * validate the top title
     */
    const validateTopTitle = () => {
        if(learningTitle === "") {
            setValidationMessage("Error: title cannot be empty. Please enter it at the top"); 
            setIsShowValidationMsg(true);
            if (stepTitleTextRef.current) {
                stepTitleTextRef.current.focus();
            }
            return false;
        }
        return true;
    };

    /**
     * validate the steps
     */
    const validateLearningStepItems = () => {
        if(learningSteps.length == 0 ) {
            setValidationMessage("Error: No steps created. Please at least create one step to publish."); 
            setIsShowValidationMsg(true);
            if (stepTitleContentTextRef.current) {
                stepTitleContentTextRef.current.focus();
            }
            return false;
        }
        return true;
    };

    /**
     * validate on publish button and show modal box to confirm
     */
    const onPublishLearningBtnClick = () => {
        setValidationMessage(""); // 0 - clear notification and hide
        setIsShowValidationMsg(false);
        if(!validateTopTitle()) {
            return;
        }
        if(!validateLearningStepItems()){
            return;
        }

        setIsConfirmPublishBoxOpen(true);
    };

    const onPreviewLearningBtnClick = () => {};

    /**
     * handle the logic to publish a learning
     */
    const onPublishModalBoxConfirm = async () => {
        if(!isEditMode){ // create new mode
            const learningRecordToAdd = { authorId: loginUserId,  title: learningTitle, learingSteps: learningSteps };
            const data = await submitLearningRecord(learningRecordToAdd); 
            setIsShowSubmitAnimation(true);
            setTimeout(() => {
                setLearningTitle("");
                setActiveResTypeBtn("content");
                setIsAddingContentResItem(true);
                setResURLContentToAdd("");
                setStepTitleContentToAdd("");
                setLearningSteps([]); 
                setIsShowSubmitAnimation(false); 
                setIsConfirmPublishBoxOpen(false);
            }, 2500);
        } else { // edit mode
            const learningRecordToEdit = { id: isEditingLearningId, authorId: loginUserId, title: learningTitle, learingSteps: learningSteps };
            const data = await updateLearningRecord(learningRecordToEdit);
            if(data.error)
                return;
            setIsShowSubmitAnimation(true);
            setTimeout(() => {
                setIsShowSubmitAnimation(false); 
                setIsConfirmPublishBoxOpen(false);
                window.location.href = `/home?rid=${isEditingLearningId}`;
            }, 3500);
        }
    };

    /**
     * This method submit a learning record (with all the stepItems) to the server
     * @param {*} learningRecord 
     * @returns 
     */
    const submitLearningRecord = async (learningRecord) => {
        try {
            const response = await axios.post('/records/new', learningRecord, {
                headers: { 'Content-Type': 'application/json' }
            });
            return response.data;
        } catch (error) {
            console.log("error occurred: ", error);
        }
    };

    /**
     * This method post an edited learning record (with all the stepItems) to the server
     * to update the existing one
     * @param {*} learningRecord 
     * @returns 
     */
    const updateLearningRecord = async (learningRecord) => {
        try {
            const response = await axios.post('/records/edit', learningRecord, {
                headers: { 'Content-Type': 'application/json' }
            });
            return response.data;
        } catch (error) {
            console.log("error occurred: ", error);
        }
    };

    /**
     * This method fetch a learning record by using a record id
     * It will set the local states
     * @param {*} rid 
     * @returns 
     */
    const fetchLearningRecord = async (rid) => {
        try {
            const response = await axios.get(`/record/${rid}`);
            const learningRecord = response.data;
            if (!learningRecord) return;
    
            setLearningTitle(learningRecord.title);
            console.log("learningRecord -- ");
            console.log(learningRecord);

            const steps = getLearningStepsFromItem(learningRecord);
            console.log("steps");
            console.log(steps);
            setLearningSteps(steps);
        } catch (error) {
            console.log("error occurred: ", error);
        }
    };

    /**
     * a method to convert item's steps into the steps that can be used on this page
     */
    const getLearningStepsFromItem = (learningRecord) => {
        let learningSteps = [];
        learningRecord.steps.map((item) => {
            let resItems = [];
            item.resources.map((res)=>{
                const resource = {
                    id: res.id,
                    type: res.type,
                    content: res.content,
                };
                resItems.push(resource);
            });
            const step = {
                id: item.id,
                titleContent: item.title,
                resourceItem: resItems,
            };
            learningSteps.push(step);
        });
        return learningSteps;
    };

    const onPublishModalBoxCancel = () => {
        setIsConfirmPublishBoxOpen(false);
    };

    const onCancelLearningBtnClick = () => {
        setIsConfirmCancelBoxOpen(true);
    };

    const onResetEditBtnClick = () => {
        setCancelResetTxt(confirmResetStepTxt);
        setIsConfirmCancelBoxOpen(true);
    };

    const onUpdateLearningBtnClick = () => {
        setValidationMessage(""); // 0 - clear notification and hide
        setIsShowValidationMsg(false);
        if(!validateTopTitle()) {
            return;
        }
        if(!validateLearningStepItems()){
            return;
        }
        setCreateUpdateTxt(confirmUpdateTxt);
        setIsConfirmPublishBoxOpen(true);
    };

    const onCancelModalBoxConfirm = () => {
        if(!isEditMode){
            setLearningTitle("");
            setActiveResTypeBtn("content");
            setIsAddingContentResItem(true);
            setResURLContentToAdd("");
            setStepTitleContentToAdd("");
            setLearningSteps([]);
        } else {
            fetchLearningRecord(isEditingLearningId);
        }
        setIsConfirmCancelBoxOpen(false);
    }

    const onCancelModalBoxCancel = () => {
        setIsConfirmCancelBoxOpen(false);
    }

    const onDeletionModalBoxConfirm = () => {
        if(!learningSteps || learningSteps.length==0 || !toDeleteStepID) {
            return;
        }
        const updatedList = learningSteps.filter((step) => step.id !== toDeleteStepID);
        setLearningSteps(updatedList);
        setToDeleteStepID(null);
        setIsConfirmDeleteBoxOpen(false);
    };

    const onDeletionModalBoxCancel = () => {
        setIsConfirmDeleteBoxOpen(false);
    };

    const onDeleteStepBtnClick = (e) => {
        const delBtn = e.currentTarget;
        const stepBtnDiv = delBtn.parentNode;
        const dataRid = stepBtnDiv.dataset.rid;
        setToDeleteStepID(dataRid);
        setIsConfirmDeleteBoxOpen(true);
    };

    const onStepItemResTypeChange = (index, type) => {
        const newLearningSteps = [...learningSteps];
        newLearningSteps[index].resourceItem[0].type = type;
        setLearningSteps(newLearningSteps);
        setActiveResTypeBtnsIndex(null);
    };

    /**
     * reorder the step items
     */
    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
    
        return result;
    };

    const handleStepTitleChange = (index, event) => {
        const newLearningSteps = [...learningSteps];
        newLearningSteps[index].titleContent = event.target.value;
        setLearningSteps(newLearningSteps);
        adjustTextAreaHeight(event.target);
    };

    const handleStepResChange = (index, event) => {
        if (learningSteps[index].resourceItem && learningSteps[index].resourceItem.length > 0) {
            const newLearningSteps = [...learningSteps];
            newLearningSteps[index].resourceItem[0].content = event.target.value;
            setLearningSteps(newLearningSteps);
            adjustTextAreaHeight(event.target);
        }
    };

    const onStepResTypeBtnClick = (index) => {
        if(activeResTypeBtnsIndex === index) {
            setActiveResTypeBtnsIndex(null);
        } else {
            setActiveResTypeBtnsIndex(index);
        }
    };

    const onAddStepItemBtnClick = async (event) => {
        setValidationMessage(""); // 0 - clear notification and hide
        setIsShowValidationMsg(false);

        if (!validateStepItem()) { // 1 - validate step text
            return;
        }
        if (!await validateResource()) { // 2 - validate the resource item to be added
            return;
        }
        const nid = new ObjectId();

        // add logic to handle image upload and display
        let imgToAdd = null;
        if (activeResTypeBtn === "image") {
            imgToAdd = resImageToAdd;
        }

        const resItemToAdd = {
            id: nid.toString(),
            type: activeResTypeBtn,
            content: resURLContentToAdd,
            image: imgToAdd,
        };

        const sid = new ObjectId();
        const stepItemToAdd = {
            id: sid.toString(),
            titleContent: stepTitleContentToAdd,
            resourceItem: [resItemToAdd],
        };
        setLearningSteps(prevItems => [...prevItems, stepItemToAdd]);
        setStepTitleContentToAdd("");
        setResURLContentToAdd("");
        setActiveResTypeBtn('content');
        setIsAddingContentResItem(false);
        setIsShowAddStepDiv(false);
    };

    const onCloseStepItemBtnClick = (event) => {
        setStepTitleContentToAdd("");
        setResURLContentToAdd("");
        setActiveResTypeBtn('youtube');
        setIsAddingContentResItem(false);
        setIsShowAddStepDiv(false);
    };

    const onShowAddStepDivBtnClick = () => {
        setIsShowAddStepDiv(true);
    };

    /**
    * This function close the validation message div
    */
    const onCloseValidateMsg = () => {
        setIsShowValidationMsg(false);
        setValidationMessage("");
    };

    /**
     * This function adjust the textarea's height based on its content
     * @param {*} textarea 
     */
    const adjustTextAreaHeight = (textarea) => {
        textarea.style.height = 'inherit'; // Reset height
        const computed = window.getComputedStyle(textarea);
        const height = parseInt(computed.getPropertyValue('border-top-width'), 10)
                     + parseInt(computed.getPropertyValue('border-bottom-width'), 10)
                     + textarea.scrollHeight;
    
        textarea.style.height = `${height}px`;
    };

    const onUploadSuccess = (image) => {
        console.log(image);
        console.log("Image uploaded with ID:", image._id);
        //setResImageBuffToAdd(image.data);
        setResImageToAdd(image);
        setResURLContentToAdd(image._id);
    };

    function arrayBufferToBase64(buffer) {
        let binary = '';
        const bytes = new Uint8Array(buffer);
        const len = bytes.byteLength;
        for (let i = 0; i < len; i++) {
          binary += String.fromCharCode(bytes[i]);
        }
        return btoa(binary);
      }

    /**
     * The method to render each dragable stepItem, its resources, and handle its events
     * @param {*} stepItem 
     * @param {*} index 
     * @returns render the stepItem div 
     */
    const RenderStepItem = (stepItem, index) => {

        var resItem = stepItem.resourceItem[0];

        return <>
                    {/*<div className='learning-step-index'>{(index+1)}</div>*/}
                    <div className='learning-step-name'>
                        <textarea className='learning-step-name-item-input auto-adjust' type='text' value={stepItem.titleContent} onChange={e => handleStepTitleChange(index, e)}></textarea>
                    </div>
                    <div className='learning-step-btns' data-rid={stepItem.id}>
                        <button className='side-s-btn' onClick={()=>onStepResTypeBtnClick(index)}><AiOutlineFileSearch /></button>
                    </div>
                    { activeResTypeBtnsIndex !== index ? <>
                    <div className='learning-step-res'>
                        <div className='learning-step-res-item'>
                            {/** the step resource items list*/}
                            {/*<textarea className='learning-step-name-item-input auto-adjust' type='text' value={stepItem.resourceItem[0].content} onChange={e => handleStepResChange(index, e)} ></textarea>*/}
                            {resItem.type === 'image' ? (
                                // display image if the type is 'image'
                                <img src={`data:${resItem.image.mimeType};base64,${arrayBufferToBase64(resItem.image.data.data)}`} alt="Resource" className="learning-step-image-item display-img" />
                            ) : (
                                // display textarea if the type is not 'image'
                                <textarea className='learning-step-name-item-input auto-adjust' type='text' value={resItem.content} onChange={e => handleStepResChange(index, e)} ></textarea>
                            )}

                        </div>
                    </div>
                    <div className='learning-step-btns' data-rid={stepItem.id}>
                        <button className='side-s-btn side-btn-hovers' onClick={(e)=>onDeleteStepBtnClick(e)}><BsTrash /></button>
                    </div>
                    </> : <>
                        <div className='learning-step-add-res'>
                            <div className='learning-step-res-type'>
                                <button style={getStepItemResTypeBtnStyle(index, 'content')} onClick={() => onStepItemResTypeChange(index, 'content')}><AiOutlineFileText /> Text</button>
                                <button style={getStepItemResTypeBtnStyle(index, 'image')} onClick={() => onStepItemResTypeChange(index, 'image')}><IoImageOutline /> Image</button>  
                                <button style={getStepItemResTypeBtnStyle(index, 'youtube')} onClick={() => onStepItemResTypeChange(index, 'youtube')}><AiOutlineYoutube /> Youtube</button>
                                <button style={getStepItemResTypeBtnStyle(index, 'webpage')} onClick={() => onStepItemResTypeChange(index, 'webpage')}><AiOutlineLink /> Webpage</button>
                            </div>
                        </div>
                        <div className='learning-step-btns' data-rid={stepItem.id}>
                            {/**<button className='side-s-btn step-save-btn' onClick={(e)=>onDeleteStepBtnClick(e)}><RiDeleteBin5Line /></button>*/}
                        </div>
                    </>
                    }  
                </>
    };

    return ( 
    <>
        <div className='container share-editor-wrapper'>
            {isEditMode && <div className='edit-mode-div'>Editing Mode</div>}
            <div className='learning-title-div'>   
                <textarea ref={stepTitleTextRef} class="form-control learning-title-textarea" name="question-title" as="textarea" rows='1' 
                            placeholder={titlePlaceholder} value={learningTitle} onChange={(e)=>onShareTitleTextChange(e)} />
                {/* <div className='title-top-div'>
                    <div className='learning-remaining-chars-div'> {maxTitleCharNum - learningTitle.length} characters remaining </div>
                </div> */}
            </div>

            <div className="learning-content-container">
                {/** the step items list*/}
                <DragDropContext onDragEnd={onStepItemDragEnd}>
                    <Droppable droppableId="learning-item-dropzone">
                            {(provided) => (
                                <div ref={provided.innerRef} {...provided.droppableProps}>
                                {learningSteps.map((stepItem, index) => (
                                    <Draggable key={stepItem.id} draggableId={stepItem.id} index={index}>
                                        {(provided) => (
                                            <div
                                                className='learning-step-item'
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                            >
                                                <StepItem
                                                    stepItem={stepItem}
                                                    index={index}
                                                    handleStepTitleChange={handleStepTitleChange}
                                                    handleStepResChange={handleStepResChange}
                                                    onStepResTypeBtnClick={onStepResTypeBtnClick}
                                                    activeResTypeBtnsIndex={activeResTypeBtnsIndex}
                                                    onDeleteStepBtnClick={onDeleteStepBtnClick}
                                                    onStepItemResTypeChange={onStepItemResTypeChange}
                                                    getStepItemResTypeBtnStyle={getStepItemResTypeBtnStyle}
                                                />
                                            </div>
                                        )}
                                    </Draggable>
                                ))}

                                    {provided.placeholder}
                                </div>
                            )}
                    </Droppable>
                </DragDropContext>

                {isShowAddStepDiv &&
                <div className='learning-step-inputs block-mobile'>
                    <div className='learning-step-title-add'>
                        <textarea ref={stepTitleContentTextRef} className="learning-step-name-add form-control request-step-textarea" name="step-title"  as="textarea" 
                            placeholder={stepTitlePlaceholder} value={stepTitleContentToAdd} onChange={(e)=>onAddStepInputChange(e, 'stepTitle')} />
                    </div>

                    <div className='learning-step-btns'>
                        <button className='side-s-btn' onClick={()=>onResMenuBtnClick()}><AiOutlineFileSearch /></button>
                    </div> 

                    <div className='learning-step-add-res'>
                        { !isAddingContentResItem && 
                        <div className='learning-step-res-type'>
                            <button style={getResTypeBtnStyle('content')} onClick={() => onResTypeBtnClick('content')}><AiOutlineFileText /> Text</button> 
                            <button style={getResTypeBtnStyle('image')} onClick={() => onResTypeBtnClick('image')}><IoImageOutline /> Image</button> 
                            <button style={getResTypeBtnStyle('youtube')} onClick={() => onResTypeBtnClick('youtube')}><AiOutlineYoutube /> Youtube</button>
                            <button style={getResTypeBtnStyle('webpage')} onClick={() => onResTypeBtnClick('webpage')}><AiOutlineLink /> Webpage</button>
                        </div>}
                        { isAddingContentResItem && ( isUploadingImage ? (<ImageUploader userId={loginUserId} onUploadSuccess={onUploadSuccess} />) : (
                            <textarea
                                ref={resourceURLRef}
                                className='learning-res-link form-control'
                                type='text'
                                placeholder={addResTextAreaPlaceholder}
                                value={resURLContentToAdd}
                                onChange={(e) => handleInputChange(e)}
                                onInput={autoResize}
                            />

                            
                            ))}
                        {/** 
                         * <textarea ref={resourceURLRef} className='learning-res-link form-control' type='text' maxLength={maxResTitleCharNum} 
                        placeholder={addResTextAreaPlaceholder} value={resURLContentToAdd} onChange={(e)=>onAddStepInputChange(e, 'resourceURL')}/>
                        */}
                    </div>
                    <div className='learning-step-btns'>
                        { isAddingContentResItem && 
                        <button className='side-s-btn' onClick={(e)=>onAddStepItemBtnClick(e)}><BsPlusCircle /></button> }
                        { !isAddingContentResItem && 
                        <button className='side-s-btn' onClick={(e)=>onCloseStepItemBtnClick(e)}><BsTrash /></button> }
                    </div>
                </div>
                }
                
                { (!isShowAddStepDiv) && (learningTitle !== '') &&
                <div className='learning-new-step-div'>
                    <button className='learning-add-step-btn' onClick={() => onShowAddStepDivBtnClick()}><BsPlusCircle />&nbsp;New Block</button>
                </div>
                }

                {/** validation message */}
                { isShowValidationMsg && <div className='learning-content-validate' onClick={()=> onCloseValidateMsg()}> {validationMessage} </div> }
                
                {/** bottom buttons */}
                <div className='learning-step-btn-div'>
                    {!isEditMode && (learningTitle !== '') && <>
                        <button className='learning-step-btn' onClick={() => onCancelLearningBtnClick()}><AiOutlineCloseCircle />&nbsp;Cancel</button>
                        {/*<button className='learning-step-btn' onClick={() => onPreviewLearningBtnClick()}><AiOutlineEye />&nbsp;Preview</button>*/}
                        <button className='learning-step-btn learning-publish-btn' onClick={() => onPublishLearningBtnClick()}><BsSend />&nbsp;Publish</button>
                    </>}
                    {isEditMode && <>
                        <button className='learning-step-btn' onClick={() => onResetEditBtnClick()}><RiRefreshLine />&nbsp;Reset</button>
                        {/*<button className='learning-step-btn' onClick={() => onPreviewLearningBtnClick()}><AiOutlineEye />&nbsp;Preview</button>*/}
                        <button className='learning-step-btn learning-publish-btn' onClick={() => onUpdateLearningBtnClick()}><AiOutlineEdit />&nbsp;Update</button>
                    </>}
                </div>

            </div>
        </div>

        {/* modal box for confirm delete */}
        {isConfirmDeleteBoxOpen && <ConfirmModal onConfirm={onDeletionModalBoxConfirm} onCancel={onDeletionModalBoxCancel} contentText={confirmDeleteStepTxt}/>}
        {/* modal box for confirm publish */}
        {isConfirmPublishBoxOpen && <ConfirmModal onConfirm={()=>onPublishModalBoxConfirm()} onCancel={onPublishModalBoxCancel} contentText={createUpdateTxt}/>}
        {/* modal box for confirm cancel */}
        {isConfirmCancelBoxOpen && <ConfirmModal onConfirm={onCancelModalBoxConfirm} onCancel={onCancelModalBoxCancel} contentText={cancelResetTxt}/>}
        {/* modal box for confirm cancel */}
        {isShowSubmitAnimation && <SubmitAnimation /> }
        {/* preview modal <PreviewModal recordItem />*/}
        
    </>
    );

};
  
export default LearningRecordPage;

