import axios from 'axios';

export const fetchAndCacheImage = async (imageId) => {

    const cachedImage = sessionStorage.getItem(`image_${imageId}`);
    if (cachedImage) {
        return cachedImage; // Return cached image if available
    }

    try {
        const response = await axios.get(`/image/${imageId}`);
        const { contentType, image } = response.data;
        const base64Image = `data:${contentType};base64,${image}`;
        sessionStorage.setItem(`image_${imageId}`, base64Image);
        return base64Image;

    } catch (error) {
        console.error("Error fetching image:", error);
        return null;
    }
};