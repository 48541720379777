import React, { useState } from 'react';
import { FilePond, registerPlugin } from 'react-filepond';
import 'filepond/dist/filepond.min.css';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';

// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview, FilePondPluginFileValidateType);

const ImageUploader = ({ userId, onUploadSuccess }) => {
    const [files, setFiles] = useState([]);

    return (
        <div className="image-uploader learning-res-link form-control">
            <FilePond
                files={files}
                onupdatefiles={setFiles}
                allowMultiple={false}
                maxFiles={1}
                name="file" // Sets the file input name
                labelIdle='Drag & Drop an image file or <span class="filepond--label-action">Browse</span>'
                acceptedFileTypes={['image/jpeg', 'image/png', 'image/bmp']}
                server={{
                    url: "/upload",
                    process: {
                      url: "/",
                      method: "POST",
                      headers: {
                        "user-id": userId, // Attach userId in the request headers
                      },
                      withCredentials: false,
                      onload: (response) => {
                        const parsedResponse = JSON.parse(response);
                        //console.log(parsedResponse.file); // for debugging
                        if (onUploadSuccess && parsedResponse.file) {
                            onUploadSuccess(parsedResponse.file);
                          }

                        return parsedResponse;
                      },
                      onerror: (response) => {
                        return JSON.parse(response);
                      },
                    },
                  }}
            />
        </div>
    );
};

export default ImageUploader;
