import axios from 'axios';

const contentThumbnailUrl = "content";
const webpageThumbnailUrl = "https://ninjasites.com/images/blog/webpage-creation.png";

const GOOGLE_API_KEY = "AIzaSyA25EgOi9tARCwrlwDA0FoMkgtJ0BWQS2U";
const GOOGLE_API_URL = 'https://www.googleapis.com/youtube/v3/videos';

export const extractYouTubeVideoID = (link) => {
    // Check if the input string matches the format of a YouTube video ID
    if (/^[a-zA-Z0-9_-]{11}$/.test(link)) {
        return link;
    }

    // Check if the input string matches a YouTube URL format and extract the video ID
    const match = link.match(/(?:youtube\.com\/(?:watch\?v=|v\/|embed\/)|youtu\.be\/)([a-zA-Z0-9_-]{11})/);
    if (match) {
        return match[1];
    }

    // If neither condition is met, return null
    return null;
};

export const extractVideoIds = (recordItem) => {
    const stepItems = recordItem.steps;
    let vIDs = [];
    // eslint-disable-next-line array-callback-return
    stepItems.map((stepItem) => {
        if (stepItem.resources[0].type === "youtube") {
            const videoURL = stepItem.resources[0].content;
            const videoID = extractYouTubeVideoID(videoURL);
            vIDs.push(videoID);
        }
        else if (stepItem.resources[0].type === "content") {
            vIDs.push("content##");
        }
        else if (stepItem.resources[0].type === "webpage") {
            vIDs.push("webpage##");
        }
        else if (stepItem.resources[0].type === "image") {
            vIDs.push("image##");
        }
    });
    return vIDs;
};

export const saveVideoInfoToSession = (id, videoInfo) => {
    const data = JSON.stringify(videoInfo);
    sessionStorage.setItem(`videoinfo_${id}`, data);
};

export const getVideoInfoFromSession = (id) => {
    const data = sessionStorage.getItem(`videoinfo_${id}`);
    return data ? JSON.parse(data) : null;
};

export const fetchTitleAndThumb = async (videoId) => {

    if(videoId === "content##") {
        return { title: "Content", thumbUrl: contentThumbnailUrl };
    } 
    else if (videoId === "webpage##") {
        return { title: "Webpage", thumbUrl: webpageThumbnailUrl };
    }
    else if (videoId === "image##") {
        return { title: "Image", thumbUrl: webpageThumbnailUrl }; // should use image thumbnail here
    }

    try {
        const response = await axios.get(`${GOOGLE_API_URL}?key=${GOOGLE_API_KEY}&id=${videoId}&part=snippet`);
        //const videoData = response.data.items[0].snippet;
        const videoData = response.data?.items?.[0]?.snippet;
        //console.log(videoData);
        if(!videoData)
            return;

        if(videoData.thumbnails?.medium.url) {
            return { title: videoData.title, thumbUrl: videoData.thumbnails.medium.url };
        } else {
            return null;
        }
    } catch (error) {
        console.error('Error:', error);
        return null;  
    }
};

