import React from 'react';

class AboutUs extends React.Component {
    render() {
        const textStyle = {
            fontSize: '24px', // Adjust this value to make the text larger
            lineHeight: '1.5', // Optional: Adjust line height if needed
            fontWeight: 'bold', // Optional: Make the text bold if desired
        };

        return (
            <div className="page-wrapper-div">
                <div className="about-us-content">
                    <p style={textStyle}>Potential - Your Infinite Possibilities.</p>
                    <p style={textStyle}>
                        At Potential, we believe in empowering and connecting you to explore limitless possibilities and reach new heights in any path you choose.
                    </p>
                    <p style={textStyle}>
                        Share your journey or explore new ideas—Potential is where experiences ignite inspiration and possibilities.
                    </p>
                    <p style={textStyle}>Let us explore the possibilities—together.</p>
                </div>
            </div>
        );
    }
}

export default AboutUs;
