import React, { useEffect, useState, useContext, useRef  } from 'react';
import { UserContext } from "./UserProvider";
import data from '@emoji-mart/data';
import Picker from '@emoji-mart/react';
import Editor from 'draft-js-plugins-editor';
import { EditorState, Modifier, convertToRaw, ContentState, convertFromRaw  } from 'draft-js';
import createToolbarPlugin from 'draft-js-static-toolbar-plugin';
import 'draft-js-static-toolbar-plugin/lib/plugin.css';
import { RiCloseCircleFill } from "react-icons/ri";
import { BiCool } from "react-icons/bi";
import 'draft-js/dist/Draft.css';

const staticToolbarPlugin = createToolbarPlugin();
const { Toolbar } = staticToolbarPlugin;
const plugins = [staticToolbarPlugin];

// this is a class component to display the item details
// shows when the square item is clicked
const NewRequestModalBox = () => {

    const titlePlaceholder = "What experience would you like to request from others?";
    const notePlaceholder = ""; //"(optional) Any detailed description for your question?";
    const initialContent = ContentState.createFromText(notePlaceholder);

    const [validateMessage, setValidateMessage] = useState('');
    const [question, setQuestion] = useState('');
    const [emojiPicker, setEmojiPicker] = useState(false);
    const [editorState, setEditorState] = useState(EditorState.createWithContent(initialContent));
    const {loginUserId} = useContext(UserContext);
    const [isRemainCharShow, setIsRemainCharShow] = useState(true);

    const [isEditorTouched, setIsEditorTouched] = useState(false); 

    const [showAnimation, setShowAnimation] = useState(false);
    const closeBtnRef = useRef(null);
    const maxCharNum = 170;

    useEffect(() => { 
    }, []);

    const handleEditorFocus = () => {
        console.log("focused");
        const contentState = editorState.getCurrentContent();
        const text = contentState.getPlainText().trim();
        console.log(text);

        if (!isEditorTouched && text === notePlaceholder) {
            console.log("reached here");
            const emptyContent = ContentState.createFromText('');
            setEditorState(EditorState.createWithContent(emptyContent)); 
            setIsEditorTouched(true); // Mark editor as touched so it doesn't reset again
        }

    };


    /**
     * handle when 'emoji' button is clicked
     */
    const onClickEmojiPicker = () => {
        setEmojiPicker(!emojiPicker);
    };

    /**
     * handle when 'submit' button is clicked
     */
    const onSubmitRequest = async () => {
        const contentState = editorState.getCurrentContent();
        const requestText = contentState.getPlainText();      
        if (question === "") {
            //show validation msg, then return
            setValidateMessage("Please enter your question.");
            setTimeout(()=>{ setValidateMessage("");}, 5001);
            return;
        }
        let noteContent = "";
        if (requestText.trim() !== "") { 
            const rawContentState = convertToRaw(contentState);
            const contentJSONString = JSON.stringify(rawContentState);
            noteContent = contentJSONString;
        } 
        const requestToAdd = { authorId: loginUserId,  note: noteContent, question: question};
        const data = await createNewRequest(requestToAdd); 
        if(data.requestId) { //successfully added
            setQuestion("");
            setEditorState(EditorState.createEmpty());
            setShowAnimation(true);
            setTimeout(() => { setShowAnimation(false); }, 2500);
            closeBtnRef.current.click();
            return;
        }
    };

    /**
     * when click the validate div, hide the msg
     */
    const hideValidateMsg = () => {
        setValidateMessage("");
    };
    
    /**
     * post a new request to server
     */
    const createNewRequest = async (requestToAdd) => {
        try {
            const response = await fetch(`/requests/add`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(requestToAdd)
            });
            const data = await response.json();
            return data;
        } catch (error) {
            console.log("error occurred: ", error);
        }
    };

    /**
     * handle when emoji being selected  
     */
    const onEditorEmojiSelect = (emoji) => {
        const selection = editorState.getSelection();
        let contentState = editorState.getCurrentContent();
        const emojiStr = emoji.native;
        contentState = Modifier.replaceText( contentState, selection, emojiStr );
        setEditorState( EditorState.push(editorState, contentState, 'insert-characters') );
        setEmojiPicker(false);
    };

    const onTitleTextareaChange = (e) => {
        setQuestion(e.target.value);
        e.target.style.height = "auto";
        e.target.style.height = `${e.target.scrollHeight}px`;
    };

    /**
     * render animation element
     */
    const renderAnimation = () =>{
        return <div class="container">
                <svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                <circle class="checkmark__circle" cx="100" cy="100" r="50" fill="none"/>
                <path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/>
                </svg>
                </div>
    };

    

    return (
        <>
        <div class="modal fade" id="newRequestModal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div class="modal-dialog">
            <div class="modal-content">
                {/* the header - user image - title */}
                <div class="modal-header new-request-header">
                    {/*<div className="add-request-title-box">Add a New Request To Learn About</div>*/}
                    <button ref={closeBtnRef} type="button" class="btn-close square-detail-close-btn new-request-close-btn" data-bs-dismiss="modal" aria-label="Close"><RiCloseCircleFill/></button>
                </div>
                <div class="modal-body square-add-request-body">
                    <textarea class="form-control request-title-textarea" name="question-title"  as="textarea" rows='1' 
                                placeholder={titlePlaceholder} maxLength={maxCharNum}
                                value={question} 
                                onChange={onTitleTextareaChange} />
                    {isRemainCharShow && <div className='remaining-chars-div'> {maxCharNum - question.length} characters remaining </div>}
                    <Editor className="editor-area" editorState={editorState} onChange={setEditorState} onFocus={handleEditorFocus} plugins={plugins} />
                    <div className="editor-toolbar"><Toolbar></Toolbar></div>
                    <button className="comment-btn-emj" onClick={onClickEmojiPicker}><BiCool/></button>
                    {emojiPicker ? (<Picker className="emj-picker" data={data} onEmojiSelect={onEditorEmojiSelect} />) : null}
                    <button className="comment-btn new-request-submit-btn" onClick={onSubmitRequest}>Submit</button>
                    <div className="validate-comment-msg" onClick={hideValidateMsg}>{validateMessage}</div>

                </div>
            </div>
            </div>
        </div>
        {showAnimation && renderAnimation()}
        </>
    );

  };
  
  export default NewRequestModalBox;